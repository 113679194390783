
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { formatCurrency, formatDate } from "@/utils/formatters";
  import { TeamFunctions } from "@/store/modules/activity-account.store";
  import { formatTooltip } from "@/utils/formatters";
  import { bus } from "@/main";

  export interface DossierRowColumn {
    showInHeader?: boolean;
    type:
      | "name"
      | "askingprice"
      | "salesprice"
      | "start_bo"
      | "purchase_promise"
      | "suspensive_conditions"
      | "team_buyer"
      | "team_startup"
      | "team_seller"
      | "create_sales_promise"
      | "seller_notary"
      | "seller_notary_aggreement"
      | "buyer_notary"
      | "buyer_notary_aggreement"
      | "sign_sales_aggreement"
      | "memo";
    label?: string;
    width?: string;
    center?: boolean;
    headerCenter?: boolean;
  }

  @Component({
    components: {
      GeneralItemRow: () => import("@/components/general/item-row.vue"),
      UserImage: () => import("@/components/general/user-image.vue"),
      notaryStatus: () => import("./notary-status.vue"),
      salesPromiseStatus: () => import("./sales-promise-status.vue"),
    },
  })
  export default class DossierRow extends Vue {
    @Prop() activity!: Activity;

    @Prop() columns!: DossierRowColumn[];

    @Prop({ default: () => "link" }) mode?: "link" | "accordion";

    formatCurrency = formatCurrency;

    formatDate = formatDate;

    formatTooltip = formatTooltip;

    bus = bus;

    /**
     * Get the buyer of the activity
     *
     * @returns { ActivityAccount }
     */
    get buyer() {
      return this.activity.assigned_to.find((item) => item.type === TeamFunctions.buyer);
    }

    /**
     * Get the startup of the activity
     *
     * @returns { ActivityAccount }
     */
    get startup() {
      return this.activity.assigned_to.find((item) => item.type === TeamFunctions.startup);
    }

    /**
     * Get the seller of the activity
     *
     * @returns { ActivityAccount }
     */
    get seller() {
      return this.activity.assigned_to.find((item) => item.type === TeamFunctions.seller);
    }
  }
